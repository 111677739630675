<template>
  <div class="details_cycle">
    <Loading v-if="loading"></Loading>
    <v-container fluid class="py-8">
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row>
        <v-col cols="12" class="">
          <Header :name="$t('cycle.Details Cycle')"></Header>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <v-card class="card-shadow border-radius-xl h-100">
            <div class="px-4 pt-5 d-flex align-center">
              <h6 class="mb-0 text-typo text-h6 font-weight-bold">
                {{ $t("cycle.General Information") }}
              </h6>
            </div>
            <div class="px-4 pt-6">
              <!-- <h6
                  class="
                    text-uppercase text-body text-xs
                    font-weight-bolder
                    mb-3
                  "
                >
                  {{ $t("cycle.title in english") }}
                </h6> -->
              <div>
                <v-list-item class="px-0 py-3">
                  <v-btn
                    :ripple="false"
                    icon
                    outlined
                    rounded
                    width="34px"
                    height="34px"
                    class="me-4"
                  >
                    <v-icon size="13">mdi-message-text</v-icon>
                  </v-btn>

                  <v-list-item-content class="py-0">
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-typo text-sm font-weight-bold">
                        {{ $t("cycle.title in english") }}
                      </h6>
                      <span class="text-xs text-body">{{ cycle.title }}</span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0 py-3">
                  <v-btn
                    :ripple="false"
                    icon
                    outlined
                    rounded
                    width="34px"
                    height="34px"
                    class="me-4"
                  >
                    <v-icon size="13">mdi-message-text</v-icon>
                  </v-btn>

                  <v-list-item-content class="py-0">
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-typo text-sm font-weight-bold">
                        {{ $t("cycle.title in arabic") }}
                      </h6>
                      <span class="text-xs text-body">{{
                        cycle.title_ar
                      }}</span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0 py-3">
                  <v-btn
                    :ripple="false"
                    icon
                    outlined
                    rounded
                    width="34px"
                    height="34px"
                    class="me-4"
                  >
                    <v-icon size="13">mdi-message-text</v-icon>
                  </v-btn>

                  <v-list-item-content class="py-0">
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-typo text-sm font-weight-bold">
                        {{ $t("cycle.Description in english") }}
                      </h6>
                      <span class="text-xs text-body">{{
                        cycle.description
                      }}</span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0 py-3">
                  <v-btn
                    :ripple="false"
                    icon
                    outlined
                    rounded
                    width="34px"
                    height="34px"
                    class="me-4"
                  >
                    <v-icon size="13">mdi-message-text</v-icon>
                  </v-btn>

                  <v-list-item-content class="py-0">
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-typo text-sm font-weight-bold">
                        {{ $t("cycle.Description in arabic") }}
                      </h6>
                      <span class="text-xs text-body">{{
                        cycle.description_ar
                      }}</span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0 py-3">
                  <v-btn
                    :ripple="false"
                    icon
                    outlined
                    rounded
                    width="34px"
                    height="34px"
                    class="me-4"
                  >
                    <v-icon size="13">far fa-calendar-alt</v-icon>
                  </v-btn>

                  <v-list-item-content class="py-0">
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-typo text-sm font-weight-bold">
                        {{ $t("cycle.Start Date") }}
                      </h6>
                      <span class="text-xs text-body">{{ cycle.start }}</span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0 py-3">
                  <v-btn
                    :ripple="false"
                    icon
                    outlined
                    rounded
                    width="34px"
                    height="34px"
                    class="me-4"
                  >
                    <v-icon size="13">far fa-calendar-alt</v-icon>
                  </v-btn>

                  <v-list-item-content class="py-0">
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-typo text-sm font-weight-bold">
                        {{ $t("cycle.Expire Date") }}
                      </h6>
                      <span class="text-xs text-body">{{ cycle.expire }}</span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-group
                  prepend-icon="mdi-clipboard-text"
                  v-model="active"
                  no-action
                  class="px-0 py-3 stages"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("stage.Stages")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item
                    v-for="item in cycle.stages"
                    :key="item.id"
                    class="px-0 py-3"
                  >
                    <v-btn
                      :ripple="false"
                      icon
                      width="34px"
                      height="34px"
                      class="me-4"
                    >
                      <v-icon size="13">mdi-label</v-icon>
                    </v-btn>

                    <v-list-item-content class="py-0">
                      <div class="d-flex flex-column">
                        <h6 class="mb-1 text-typo text-sm font-weight-bold">
                          {{ $t("cycle.stage") }} {{ item.pivot.sort }}
                        </h6>
                        <span class="text-xs text-body">{{ item.name }}</span>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="7" class="" v-if="cycle.orders && cycle.orders.length > 0">
          <v-card class="card-shadow border-radius-xl">
            <div class="px-4 pt-5 d-flex align-center">
              <h6 class="mb-0 text-typo text-h6 font-weight-bold">
                {{ $t("sidebar.customers") }}
              </h6>
              <div class="text-body ms-auto">
                <v-icon class="text-body" size="16">fa fa-users me-2</v-icon>
                <!-- <v-btn
                  :ripple="false"
                  icon
                  outlined
                  rounded
                  width="34px"
                  height="34px"
                  class="me-4"
                  color="green"
                >
                </v-btn> -->
                <small>{{ cycle.orders.length }}</small>
              </div>
            </div>
            <div class="px-4 pt-6 pb-1">
              <div v-for="item in cycle.orders" :key="item.id">
                <v-list-item
                  class="px-0 py-3 bg-gray-100 border-radius-lg p-6 mb-6"
                >
                  <v-list-item-content class="pa-6">
                    <div class="d-flex flex-column">
                      <h6 class="mb-3 text-sm text-typo font-weight-bold">
                        {{ item.users.name }}
                      </h6>
                      <span class="mb-2 text-xs text-body"
                        >{{ $t("user.Email") }}
                        <span class="text-dark ms-sm-2 font-weight-bold">{{
                          item.users.email
                        }}</span></span
                      >
                      <span class="mb-2 text-xs text-body"
                        >{{ $t("auth.Phone Number") }}
                        <span class="text-dark font-weight-bold ms-sm-2">{{
                          item.users.phone
                        }}</span></span
                      >
                      <span class="mb-2 text-xs text-body"
                        >{{ $t("general.Created at apply") }}
                        <span class="text-dark font-weight-bold ms-sm-2">{{
                          item.created_at
                        }}</span></span
                      >
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Loading from "../Components/Loading.vue";
import Header from "../Components/Header.vue";
import Notify from "../Components/Old/Notify.vue";
import CycleService from "../../services/cycle.service";
export default {
  name: "Cycle-Details",
  components: { Loading, Header, Notify },
  data() {
    return {
      loading: true,
      active: false,
      message: { msg: null, type: null },
      cycle: {},
    };
  },
  computed: {
    classLang() {
      return {
        "ml-9": this.$vuetify.rtl,
        "mr-9": !this.$vuetify.rtl,
      };
    },
  },
  methods: {
    details() {
      return CycleService.details_cycle(this.$route.params.id).then(
        (response) => {
          // console.log(response);
          this.cycle = response.data.cycle;
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.message = {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          };
        }
      );
    },
  },
  mounted() {
    this.details();
  },
};
</script>
<style scoped>
/* .details_cycle .v-list-item__title {
  color: #777;
  font-weight: bold;
}
.details_cycle .v-list-item__icon {
  margin-right: 15px;
} */
</style>