<template >
  <div class="mb-4">
    <Loading v-if="loading"></Loading>
    <v-app-bar color="light" class="border-left-primary box-shadow-none">
      <h5 class="text-h5 f text-typo">{{name}}</h5>
    </v-app-bar>
  </div>
</template>
<script>
import Loading from "../Components/Loading.vue";
export default {
  name: "Header",
  props: ["name","loading"],
  components:{
    Loading
  },
  data() {
    return {
      
    };
  },
};
</script>