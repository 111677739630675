class InquiryService {
  get_cycles(page) {
    return axios.get('cycles?page=' + page)
  }
  get_cycle(id) {
    return axios.get('cycles/edit/' + id)
  }
  add_cycle(data) {
    return axios.post('cycles/store', data)
  }
  details_cycle(id, data) {
    return axios.get('cycles/show/' + id)
  }
  edit_cycle(id, data) {
    return axios.post('cycles/update/' + id, data)
  }
  destroy_cycle(id) {
    return axios.delete('cycles/delete/' + id)
  }
  get_stages() {
    return axios.get('cycles/stages')
  }

}
export default new InquiryService();